import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'
import Post from '../../components/Home/UpdatesSection/Post/Post'

const InTheNews = ({ data }) => {
  let posts = data.allMarkdownRemark.edges.filter(post => post.node.rawMarkdownBody.length > 0)

  return (
    <Layout title={data.markdownRemark.frontmatter.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="main">
        {posts.map(post => (
          <Post title={post.node.frontmatter.title} excerpt={post.node.excerpt} photo={post.node.frontmatter.photo.photo ? post.node.frontmatter.photo.photo : null} link={`/in-the-news${post.node.fields.slug}`} date={post.node.frontmatter.publishDate} alt={post.node.frontmatter.photo.altText} />
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query InTheNewsQuery {
  markdownRemark(frontmatter: {title: {eq: "In the News"}}){
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
    }
  }
  allMarkdownRemark(
    sort: { fields: [frontmatter___publishDate], order: DESC }
    filter: { frontmatter: { newsArticle: {eq: true}}}
  ) {
    edges {
      node {
        id
        rawMarkdownBody
        timeToRead
        excerpt(pruneLength: 280)
        fields {
          slug
        }
        frontmatter {
          title
          heading
          newsOutlet
          publishDate
          photo {
            photo {
              childImageSharp {
                gatsbyImageData(transformOptions: {fit: COVER, cropFocus: ATTENTION})
              }
            }
            altText
          }
        }
      }
    }
  }
}
`

export default InTheNews